<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <UserCard></UserCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <Banner></Banner>
        <div class="mt-4 settingsBody rounded card">
          <div class="pt-3 pb-0 ps-4">
            <strong>Cambio de Contraseña</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="changePassword">
              <div class="mb-3">
                <label for="currentPassword" class="form-label"
                  >Contraseña Actual</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-bind:class="{ 'border border-danger shadow': currentError }"
                  name="currentPassword"
                  v-model="currentPassword"
                  maxlength="50"
                  minlength="8"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label"
                  >Nueva Contraseña</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-bind:class="{ 'border border-danger shadow': confirmError }"
                  name="newPassword"
                  v-model="newPassword"
                  @keyup="checkPassword"
                  maxlength="50"
                  minlength="8"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label"
                  >Confirmar Contraseña</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-bind:class="{ 'border border-danger shadow': confirmError }"
                  name="confirmPassword"
                  v-model="confirmPassword"
                  @keyup="checkPassword"
                  maxlength="50"
                  minlength="8"
                  required
                />
              </div>
              <p>
                Por favor asegúrese de que la nueva contraseña cumple los
                siguientes criterios
              </p>
              <ul class="list-group mb-3">
                <li class="list-group-item" v-bind:class="{ active: equalVal }">
                  Las contraseñas coinciden
                </li>
                <li class="list-group-item" v-bind:class="{ active: upperVal }">
                  Una letra en mayúscula
                </li>
                <li class="list-group-item" v-bind:class="{ active: lowerVal }">
                  Una letra en minúscula
                </li>
                <li class="list-group-item" v-bind:class="{ active: digitVal }">
                  Un dígito
                </li>
                <li
                  class="list-group-item"
                  v-bind:class="{ active: specialVal }"
                >
                  Un caracter especial
                  <strong>ñÑ ~!@#$%^&*+-/.,{}[]();:</strong>
                </li>
              </ul>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Banner from "@/components/layout/Banner";
import UserCard from "@/views/settings/UserCard";

import { validatePassword } from "@/modules/tools";

import Swal from "sweetalert2";
import axios from "axios";
import Storage from "@/modules/storage";

export default {
  components: { Legal, Banner, UserCard },
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      confirmError: false,
      currentError: false,
      equalVal: false,
      upperVal: false,
      lowerVal: false,
      digitVal: false,
      specialVal: false
    };
  },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Ajustes / Cambio de Contraseña");
  },
  methods: {
    checkPassword() {
      if (this.newPassword === "" && this.confirmPassword === "") {
        this.equalVal = false;
      } else if (this.newPassword === this.confirmPassword) {
        this.equalVal = true;
      } else {
        this.equalVal = false;
      }

      const lowercase = "abcdefghijklmnopqrstuvwxyz";
      const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const specialLetters = "ñÑ~!@#$%^&*+-/.,{}[]();: ";
      const digits = "0123456789";

      this.lowerVal = false;
      this.upperVal = false;
      this.specialVal = false;
      this.digitVal = false;

      for (let i = 0; i < this.newPassword.length; i++) {
        if (lowercase.includes(this.newPassword.charAt(i))) {
          this.lowerVal = true;
        } else if (uppercase.includes(this.newPassword.charAt(i))) {
          this.upperVal = true;
        } else if (specialLetters.includes(this.newPassword.charAt(i))) {
          this.specialVal = true;
        } else if (digits.includes(this.newPassword.charAt(i))) {
          this.digitVal = true;
        }
      }
    },
    changePassword() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      if (this.newPassword === this.currentPassword) {
        Swal.fire({
          icon: "error",
          title: "La nueva contraseña no debe ser la contraseña anterior",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        this.confirmError = true;
        this.currentError = true;
        return 0;
      }

      if (this.newPassword !== this.confirmPassword) {
        Swal.fire({
          icon: "error",
          title: "La nueva contraseña no coincide con la confirmación",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        this.confirmError = true;
        return 0;
      }

      const validate = validatePassword(this.confirmPassword);
      if (!validate[0]) {
        let msg;
        if (validate[1] === "over") {
          msg = "La nueva contraseña debe tener menos de 50 ccaracteres";
        } else if (validate[1] === "under") {
          msg = "La nueva contraseña debe tener más de 8 ccaracteres";
        } else if (validate[1] === "not valid") {
          msg =
            "La nueva contraseña contiene uno o varios caracteres no válidos:" +
            validate[2];
        } else if (validate[1] === "upper") {
          msg =
            "La nueva contraseña debe tener al menos una letra en mayúsculas";
        } else if (validate[1] === "lower") {
          msg =
            "La nueva contraseña debe tener al menos una letra en minúsculas";
        } else if (validate[1] === "digit") {
          msg = "La nueva contraseña debe tener al menos un dígito";
        } else if (validate[1] === "special") {
          msg =
            "La nueva contraseña debe tener al menos un caracter especial: " +
            validate[2];
        } else {
          msg = "Se ha producido un error inesperado";
        }
        Swal.fire({
          icon: "error",
          title: msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        this.confirmError = true;
        return 0;
      }

      const URL = new Storage().baseURL + "/auth/user/changePassword";
      const sendData = [
        {
          password: this.currentPassword,
          newPassword: this.confirmPassword
        }
      ];
      axios
        .post(URL, sendData, {
          withCredentials: true
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Contraseña Actualizada",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          this.currentPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        })
        .catch(error => {
          if (error.response.status === 401) {
            Swal.fire({
              icon: "error",
              title:
                "La contraseña actual no coincide con la registrada anteiormente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            this.currentError = true;
            return 0;
          }
          Swal.fire({
            icon: "error",
            title: "No es posible actualizar la contraseña en este momento",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        });
    }
  },
  name: "Settings Password"
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
